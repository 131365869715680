import { useState, useEffect } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { DeviceSettingsModal } from './DeviceSettingsModal.js';
import { ConfirmationDialog } from './common/ConfirmationDialog.js';
import { DeviceEventModal } from './DeviceEventModal.js';

export function DeviceTable({ backendAPI, websocket, initialDevices, isLoadingDevices, refreshDevices }) {
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [devices, setDevices] = useState(initialDevices);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Change this value for more items per page

    useEffect(() => {
        setDevices(initialDevices);
    }, [initialDevices]);

    useEffect(() => {
        websocket.registerEventCallback(handleWebsocketDeviceStatus, 'device_status');
        return () => websocket.deregisterEventCallback(handleWebsocketDeviceStatus);
    }, [websocket]);

    async function editCallback(device) {
        try {
            const response = await backendAPI.updateDevice(device);
            setTimeout(() => {
                refreshDevices();
            }, 2000);
            return response;
        } catch (error) {
            console.error('Error executing device update:', error);
            return null;
        }
    }

    async function addCallback(device) {
        try {
            const response = await backendAPI.createDevice(device);
            setTimeout(() => {
                refreshDevices();
            }, 2000);
            return response;
        } catch (error) {
            console.error('Error executing device update:', error);
            return null;
        }
    }

    const handleShowConfirmDelete = () => setIsConfirmDeleteOpen(true);

    async function handleConfirmDelete(dev) {
        try {
            const response = await backendAPI.deleteDevice(dev.id);
            if (response.status === 200) {
                refreshDevices();
                setIsConfirmDeleteOpen(false);
                return true;
            }
        } catch (error) {
            console.error('Error deleting device', error);
            return false;
        }
        return true;
    }

    const handleCloseConfirmDelete = () => setIsConfirmDeleteOpen(false);

    function handleWebsocketDeviceStatus(data) {
        setDevices((prevDevices) =>
            prevDevices.map((device) =>
                device.id === data.id ? { ...device, ...data } : device
            )
        );
    }

    // Pagination logic
    const totalPages = Math.ceil(devices.length / itemsPerPage);
    const paginatedDevices = devices.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="devices">
            <Row className="device-info gx-0 border-light-grey">
                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>MAC Address:</Col>
                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>Serial:</Col>
                <Col className="mt-1 mb-1" xs={5} lg={2}>Location:</Col>
                <Col className="mt-1 mb-1" xs={5} lg={3}>Description:</Col>
                <Col className="mt-1 mb-1" xs={1} lg={2}>Status:</Col>
                <Col className="mt-1 mb-1" xs={1} lg={1}>
                    {!isLoadingDevices && <DeviceSettingsModal device={null} submit_callback={addCallback} icon={faPlus} title="Add Device"/>}
                </Col>
            </Row>

            {isLoadingDevices && (
                <Row>
                    <Col className="mt-1 mb-1 text-center" xs={12}>
                        <label className="text-values-white">FETCHING DEVICES...</label>
                    </Col>
                </Row>
            )}

            {!isLoadingDevices && paginatedDevices.map((device, index) => (
                <div key={device.id} style={{ backgroundColor: index % 2 === 0 ? '#101014' : '#2d2d39' }} className="device">
                    <Row className="device-info gx-0 border-light-grey">
                        <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                            <label className="text-values-white">{device.mac_address}</label>
                        </Col>
                        <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                            <label className="text-values-white">{device.serial_number}</label>
                        </Col>
                        <Col className="mt-1 mb-1" xs={5} lg={2}>
                            <label className="text-values-white">{device.location}</label>
                        </Col>
                        <Col className="mt-1 mb-1" xs={5} lg={3}>
                            <label className="text-values-white">{device.description}</label>
                        </Col>
                        <Col className="mt-1 mb-1" xs={1} lg={2}>
                            <label className="text-values-white" style={{ color: device.status === 'OK' ? 'green' : 'red' }}>{device.status}</label>
                        </Col>
                        <Col className="mt-1 mb-1" xs={1} lg={1}>
                            <Row>
                                <Col xs={4}>
                                    <DeviceSettingsModal device={device} submit_callback={editCallback} icon={faEdit} title="Edit Device"/>
                                </Col>
                                <Col xs={4}>
                                    <DeviceEventModal device={device} backendAPI={backendAPI} websocket={websocket} liveDeviceEventUpdate={true} device_event={null}/>
                                </Col>
                                <Col xs={4}>
                                    <FontAwesomeIcon icon={faTrash} style={{ color: "red", cursor: "pointer" }} onClick={handleShowConfirmDelete}/>
                                    <ConfirmationDialog 
                                        title="Delete Device?"
                                        body={`Delete Device:\n\tMAC address: ${device.mac_address}\n\tSerial Number: ${device.serial_number}\n\tDescription: ${device.description}`}
                                        show={isConfirmDeleteOpen}
                                        onHide={handleCloseConfirmDelete}
                                        onConfirm={handleConfirmDelete}
                                        callback_user_data={device}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            ))}

            {/* Pagination Controls */}
            <Row className="mt-3">
                <Col className="text-center">
                    <Button 
                        variant="secondary" 
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <span className="text-values-white mx-3">Page {currentPage} of {totalPages}</span>
                    <Button 
                        variant="secondary" 
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
