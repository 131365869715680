import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Spinner } from "react-bootstrap";

import { DeviceEventModal } from './DeviceEventModal.js'

export function DeviceEventTable({ backendAPI, websocket, filters }) {
    // const backendAPI = props.backendAPI;
    // const websocket = props.websocket;

    const [device_events, setDeviceEvents] = useState([]);
    const [totalDeviceEvents, setTotalDeviceEvents] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const deviceEventsRef = useRef(device_events);
    const totalDeviceEventsRef = useRef(totalDeviceEvents);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        websocket.registerEventCallback(handleWebsocketDeviceEvent, 'device_event');
        return () => websocket.deregisterEventCallback(handleWebsocketDeviceEvent);
    }, [websocket]);

    useEffect(() => {
        async function fetchData(page, size) {
            setLoading(true);
            try {
                // const response = await backendAPI.getDeviceEvents((page - 1) * size, size);
                const response = await backendAPI.getDeviceEventsWithFilters(
                    filters.location,
                    filters.vehicleClass,
                    filters.direction,
                    filters.leftRight,
                    filters.startDate,
                    filters.endDate,
                    (currentPage - 1) * pageSize,
                    pageSize
                );
                setDeviceEvents(response.data.device_events);
                setTotalDeviceEvents(response.data.total);
            } catch (error) {
                console.error('Error getting device events:', error);
            } finally {
                setLoading(false); 
            }
        };

        fetchData(currentPage, pageSize);
    }, [backendAPI, filters, currentPage]);

    useEffect(() => {
        deviceEventsRef.current = device_events;
    }, [device_events]);

    useEffect(() => {
        totalDeviceEventsRef.current = totalDeviceEvents;
    }, [totalDeviceEvents]);

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    function handleWebsocketDeviceEvent(data) {
        if(data.is_improved_read) {
            const updatedDeviceEvents = deviceEventsRef.current.map(event => 
                event.id === data.id ? { ...event, ...data } : event
            );
            setDeviceEvents(updatedDeviceEvents);
            return; 
        }
        let newDeviceEvents = [data, ...deviceEventsRef.current];
        newDeviceEvents = newDeviceEvents.slice(0, pageSize);
        setTotalDeviceEvents(totalDeviceEventsRef.current + 1);
        setDeviceEvents(newDeviceEvents);
	}

    const formatDateTime = (datetimeStr) => {
        if (!datetimeStr) return '---';
        const date = new Date(datetimeStr);
        const fullDate = date.toLocaleDateString(); 
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); // 24-hour format e.g., "14:30"
        return { fullDate, time };
    }

    return (
        <div>
            
            <Row className="device-info gx-0 border-light-grey">
                <Col className="mt-1 mb-1" xs={3} lg={1}>Location</Col>
                <Col className="mt-1 mb-1" xs={2} lg={2}>Time</Col>
                <Col className="mt-1 mb-1" xs={3} lg={2}>VRN</Col>
                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>VRN Score</Col>
                <Col className="mt-1 mb-1" xs={3} lg={2}>Class</Col>
                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>Direction</Col>
                <Col className="mt-1 mb-1" xs={1} lg={1}></Col>
            </Row>
                

            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : device_events && device_events.map((device_event, index) => {
                const eventTime = device_event.event_time ? formatDateTime(device_event.event_time) : { fullDate: '---', time: '---' };

                return (
                    <div key={index} style={{ backgroundColor: index % 2 === 0 ? '#101014' : '#2d2d39' }} className="device_event">
                        <Row className="device-info gx-0 border-light-grey">
                            <Col className="text-truncate d-block mt-1 mb-1" xs={3} lg={1}><label className="text-values-white">{device_event.location}</label></Col>
                            <Col className="mt-1 mb-1" xs={2} lg={2}>
                                <span className="d-none d-sm-inline text-values-white">{eventTime.fullDate}</span>
                                <span>{' '}</span>
                                <span>{eventTime.time}</span>
                            </Col>
                            <Col className="mt-1 mb-1" xs={3} lg={2}><label className="text-values-white">{device_event.vrn ? device_event.vrn : '-'}</label></Col>
                            <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}><label className="text-values-white">{device_event.vrn_confidence}</label></Col>
                            <Col className="mt-1 mb-1" xs={3} lg={2}>
                                <label className="text-values-white">
                                    {device_event.vehicle_class + ' (' + device_event.vehicle_class_confidence + ')'}
                                </label>
                            </Col>
                            <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}><label className="text-values-white">{device_event.direction}</label></Col>
                            <Col className="mt-1 mb-1" xs={1} lg={1}>
                                <DeviceEventModal device={null} backendAPI={backendAPI} websocket={websocket} liveDeviceEventUpdate={false} device_event={device_event}/>
                            </Col>
                        </Row>
                    </div>
                )}
            )}

            <button onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</button>
            <button onClick={goToNextPage} disabled={(currentPage * pageSize) >= totalDeviceEvents}>Next</button>
        </div>
    );
};

export default DeviceEventTable;
