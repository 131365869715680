import React, { useState } from 'react';
import { Modal, Form, Button, Row, Col, Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Select from 'react-select';
import { format } from 'date-fns';
import { FaSearch, FaArrowUp, FaArrowDown, FaArrowsAltV } from 'react-icons/fa';

import { TransitModal } from './TransitModal.js';
import { TransitMatchModal } from './TransitMatchModal.js';

export function TransitReport({ backendAPI, zones }) {
    const [selectedZones, setSelectedZones] = useState([]);
    const [startDate, setStartDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [endDate, setEndDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [vrnSearch, setVrnSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [classFilter, setClassFilter] = useState('');
    const [showVrnSearch, setShowVrnSearch] = useState(false);
    const [showStatusFilter, setShowStatusFilter] = useState(false);
    const [showClassFilter, setShowClassFilter] = useState(false);
    const [sortOrder, setSortOrder] = useState('disabled');
    const [entrySortOrder, setEntrySortOrder] = useState('disabled');
    const [exitSortOrder, setExitSortOrder] = useState('disabled');

    const zoneOptions = zones.map((zone) => ({
        value: zone.id,
        label: zone.name,
    }));

    const handleZoneSelection = (selectedOption) => {
        setSelectedZones(selectedOption ? [selectedOption.value] : []);
    };

    const fetchReport = async () => {
        console.log("Fetching report");
        if (!selectedZones.length || !startDate || !endDate) {
            alert('Please select devices, a start date, and an end date');
            return;
        }

        setLoading(true);
        let allReportData = [];
        for (const zone of selectedZones) {
            let offset = 0;
            let total = 0;
            do {
                endDate.setHours(23, 59, 59, 999);
                
                const response = await backendAPI.getTransits(
                    [zone],
                    startDate,
                    endDate,
                    null,
                    null,
                    offset,
                    500,
                );

                if (response && response.data) {
                    const updatedTransits = response.data.zone_transits.map(data => {
                        let status = data.status;
                        if (status === 'CLOSED') {
                            if (data.entry_device_event && !data.exit_device_event) {
                                data.status = 'INCOMPLETE';
                            } else if (data.exit_device_event && !data.entry_device_event) {
                                data.status = 'INCOMPLETE';
                            }
                        } 
						// else if (status === 'EXPIRED') {
                        //     data.status = 'INCOMPLETE';
                        // }
                        return { ...data, status };
                    });
                    allReportData = allReportData.concat(response.data.zone_transits);
                    total = response.data.total;
                    offset += response.data.zone_transits.length;
                } else {
                    setLoading(false);
                    break;
                }
            } while (offset < total);
        }

        setReportData(allReportData);
        setLoading(false);
    };

    const getReportGenerationTime = () => format(new Date(), 'yy-MM-dd HH:mm:ss');

    const handleVrnSearch = (e) => setVrnSearch(e.target.value);
    const handleStatusFilterChange = (e) => setStatusFilter(e.target.value);
    const handleClassFilterChange = (e) => setClassFilter(e.target.value);

    const handleSortOrderChange = () => {
        if (sortOrder === 'disabled') {
            setSortOrder('asc');
        } else if (sortOrder === 'asc') {
            setSortOrder('desc');
        } else {
            setSortOrder('disabled');
        }
    };

    const handleEntrySortOrderChange = () => {
        if (entrySortOrder === 'disabled') {
            setEntrySortOrder('asc');
        } else if (entrySortOrder === 'asc') {
            setEntrySortOrder('desc');
        } else {
            setEntrySortOrder('disabled');
        }
    };

    const handleExitSortOrderChange = () => {
        if (exitSortOrder === 'disabled') {
            setExitSortOrder('asc');
        } else if (exitSortOrder === 'asc') {
            setExitSortOrder('desc');
        } else {
            setExitSortOrder('disabled');
        }
    };

    const filteredData = reportData.filter(data => {
        const matchesVrn = vrnSearch === '' || 
            (data.entry_device_event?.vrn || '').toLowerCase().includes(vrnSearch.toLowerCase()) ||
            (data.exit_device_event?.vrn || '').toLowerCase().includes(vrnSearch.toLowerCase());
        const matchesStatus = statusFilter === '' || data.status === statusFilter;
        const matchesClass = classFilter === '' || 
            (data.entry_device_event?.vehicle_class === classFilter || data.exit_device_event?.vehicle_class === classFilter);

        return matchesVrn && matchesStatus && matchesClass;
    });

    const sortedData = [...filteredData].sort((a, b) => {
        if (sortOrder !== 'disabled') {
            const durationA = a.entry_device_event && a.exit_device_event
                ? new Date(a.exit_device_event.event_time).getTime() - new Date(a.entry_device_event.event_time).getTime()
                : null;
            const durationB = b.entry_device_event && b.exit_device_event
                ? new Date(b.exit_device_event.event_time).getTime() - new Date(b.entry_device_event.event_time).getTime()
                : null;

            if (durationA === null) return 1;
            if (durationB === null) return -1;
            return sortOrder === 'asc' ? durationA - durationB : durationB - durationA;
        }

        if (entrySortOrder !== 'disabled') {
            const entryA = a.entry_device_event ? new Date(a.entry_device_event.event_time).getTime() : null;
            const entryB = b.entry_device_event ? new Date(b.entry_device_event.event_time).getTime() : null;

            if (entryA === null) return 1;
            if (entryB === null) return -1;
            return entrySortOrder === 'asc' ? entryA - entryB : entryB - entryA;
        }

        if (exitSortOrder !== 'disabled') {
            const exitA = a.exit_device_event ? new Date(a.exit_device_event.event_time).getTime() : null;
            const exitB = b.exit_device_event ? new Date(b.exit_device_event.event_time).getTime() : null;

            if (exitA === null) return 1;
            if (exitB === null) return -1;
            return exitSortOrder === 'asc' ? exitA - exitB : exitB - exitA;
        }

        return 0;
    });

	const exportToPDF = async () => {
		if (!selectedZones.length || !startDate || !endDate) {
			alert('Please select a zone, a start date, and an end date');
			return;
		}
	
		try {
			setLoading(true);
			const vrn_search = vrnSearch.trim();
			const status_filter = statusFilter.trim();
			const class_filter = classFilter.trim();
			const response = await backendAPI.getTransitReport(
				selectedZones[0],
				startDate,
				endDate,
				vrn_search,
				status_filter,
				class_filter,
			);
			setLoading(false);
	
			if (response.status !== 200) {
				const errorText = await response.text();
				throw new Error(`Network response was not ok: ${errorText}`);
			}
	
			const blob = response.data;
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'Transit_Report.pdf';
			document.body.appendChild(link);
			link.click();
	
			link.parentNode.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching PDF:', error);
			alert(`Failed to generate PDF report: ${error.message}`);
		}
	};
    
    return (
        <div>
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Select Zone</Form.Label>
                    <Col className="mt-1 mb-1" sm={10}>
                    <Select
                        options={zoneOptions}
                        value={zoneOptions.find(option => option.value === selectedZones[0]) || null}
                        onChange={handleZoneSelection}
                        placeholder="Select Zone"
                    />
                    </Col>
                </Form.Group>

                <hr className="my-3" />
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Start Date</Form.Label>
                    <Col className="mt-1 mb-1" sm={10}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat={"yyyy-MM-dd"}
                            placeholderText="Select Start Date"
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm={2}>End Date</Form.Label>
                    <Col className="mt-1 mb-1" sm={10}>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat={"yyyy-MM-dd"}
                            placeholderText="Select End Date"
                        />
                    </Col>
                </Form.Group>

                <hr className="my-3" />

                <center>
                    <Button onClick={fetchReport} disabled={loading}>
                        {loading ? 'Loading...' : 'Fetch Report'}
                    </Button>
                    {loading && <div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>}
					<Button onClick={exportToPDF} disabled={loading} className="ms-2">Export to PDF</Button>
                </center>
            </Form>

            {sortedData.length > 0 && <hr className="my-3" />}
            {sortedData.length > 0 && <p><strong>Date Range:</strong> {`${startDate ? format(startDate, 'yy-MM-dd') : ''} to ${endDate ? format(endDate, 'yy-MM-dd') : ''}`}</p>}
            {sortedData.length > 0 && <p><strong>Generated at:</strong> {getReportGenerationTime()}</p>}

            <Container className="mt-4" fluid>
                <Row className="border-bottom pb-2">
                    <Col className="mt-1 mb-1" xs={3} lg={1}>
                        VRN
                        <FaSearch onClick={() => setShowVrnSearch(!showVrnSearch)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        {showVrnSearch && (
                            <Form.Control
                                type="text"
                                placeholder="Search VRN"
                                value={vrnSearch}
                                onChange={handleVrnSearch}
                                style={{ marginTop: '5px' }}
                            />
                        )}
                    </Col>
                    <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                        Entry
                        {entrySortOrder === 'asc' ? (
                            <FaArrowDown onClick={handleEntrySortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        ) : entrySortOrder === 'desc' ? (
                            <FaArrowUp onClick={handleEntrySortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        ) : (
                            <FaArrowsAltV onClick={handleEntrySortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        )}
                    </Col>
                    <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                        Exit
                        {exitSortOrder === 'asc' ? (
                            <FaArrowDown onClick={handleExitSortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        ) : exitSortOrder === 'desc' ? (
                            <FaArrowUp onClick={handleExitSortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        ) : (
                            <FaArrowsAltV onClick={handleExitSortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        )}
                    </Col>
                    <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                        Class
                        <FaSearch onClick={() => setShowClassFilter(!showClassFilter)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        {showClassFilter && (
                            <Form.Select
                                value={classFilter}
                                onChange={handleClassFilterChange}
                                style={{ marginTop: '5px' }}
                            >
                                <option value="">All</option>
                                <option value="light">Light</option>
                                <option value="minibus">Minibus</option>
                                <option value="bus">Bus</option>
                                <option value="heavy">Heavy</option>
                            </Form.Select>
                        )}
                    </Col>
                    <Col className="mt-1 mb-1" xs={4} lg={2}>
                        Duration
                        {sortOrder === 'asc' ? (
                            <FaArrowDown onClick={handleSortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        ) : sortOrder === 'desc' ? (
                            <FaArrowUp onClick={handleSortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        ) : (
                            <FaArrowsAltV onClick={handleSortOrderChange} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        )}
                    </Col>
                    <Col className="mt-1 mb-1" xs={4} lg={2}>
                        Status
                        <FaSearch onClick={() => setShowStatusFilter(!showStatusFilter)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                        {showStatusFilter && (
                            <Form.Select
                                value={statusFilter}
                                onChange={handleStatusFilterChange}
                                style={{ marginTop: '5px' }}
                            >
                                <option value="">All</option>
                                <option value="OPEN">Open</option>
                                <option value="CLOSED">Closed</option>
                                <option value="INCOMPLETE">Incomplete</option>
                                <option value="MATCHED">Matched</option>
								<option value="EXPIRED">Expired</option>
                            </Form.Select>
                        )}
                    </Col>
                    <Col xs={12} lg={1}>Actions</Col>
                </Row>

                {sortedData.length > 0 ? (
                    sortedData.map((data, transit_idx) => {
                        const rowStyle = { backgroundColor: transit_idx % 2 === 0 ? '#f2f2f2' : 'white' };
                        return (
                            <Row key={transit_idx} style={rowStyle} className="border-bottom py-2">
                                <Col className="mt-1 mb-1" xs={3} lg={1}>{data.entry_device_event ? data.entry_device_event.vrn : data.exit_device_event ? data.exit_device_event.vrn : '---'}</Col>
                                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                                    {data.entry_device_event ? (
                                        <>
                                            <div>{data.entry_device.location}</div>
                                            <div>{format(new Date(data.entry_device_event.event_time), 'yyyy-MM-dd HH:mm')}</div>
                                        </>
                                    ) : '---'}
                                </Col>
                                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                                    {data.exit_device_event ? (
                                        <>
                                            <div>{data.exit_device.location}</div>
                                            <div>{format(new Date(data.exit_device_event.event_time), 'yyyy-MM-dd HH:mm')}</div>
                                        </>
                                    ) : '---'}
                                </Col>
                                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={2}>
                                    {(data.entry_device_event ? data.entry_device_event.vehicle_class : '---')
                                        + ' / ' + (data.exit_device_event ? data.exit_device_event.vehicle_class : '---')}
                                </Col>
                                <Col className="mt-1 mb-1" xs={4} lg={2}>{(data.entry_device_event && data.exit_device_event) ? Math.round((new Date(data.exit_device_event.event_time).getTime() - new Date(data.entry_device_event.event_time).getTime()) / 60000) + ' min' : '---'}</Col>
                                <Col className="mt-1 mb-1" xs={4} lg={2}>{data.status}</Col>
                                <Col className="mt-1 mb-1" xs={1} lg={1}>
                                    <Row>
                                        <Col className="mt-1 mb-1" xs={6} lg={6}><TransitModal zone={null} backendAPI={backendAPI} transit={data} /></Col>
                                        <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={6}>
                                            {data.status !== 'CLOSED' && !data.exit_device_event && (
                                                <TransitMatchModal backendAPI={backendAPI} transit={data} refetch_report_callback={fetchReport} />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    })
                ) : (
                    <Row>
                        <Col className="mt-1 mb-1 text-center" xs={12}>No data available</Col>
                    </Row>
                )}
            </Container>
        </div>
    );
}