import { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsH } from '@fortawesome/free-solid-svg-icons';
import ImageModal from './common/ImageModal.js';
import DeviceEventModal from './DeviceEventModal.js';

export function TransitMatchModal({ backendAPI, transit, refetch_report_callback }) {
    const [showModal, setShowModal] = useState(false);
    const showModalRef = useRef(showModal);

    const [anpr_image_loading, setANPRImageLoading] = useState(true);
    const [overview_image_loading, setOverviewImageLoading] = useState(true);

    const [anpr_image, setANPRImage] = useState('');
    const [overview_image, setOverviewImage] = useState('');

    const [possible_matches, setPossibleMatches] = useState([]);
    const [matchImages, setMatchImages] = useState({});

    const [loading_possible_matches, setLoadingPossibleMatches] = useState(true);

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        zIndex: 1040,
    };

    async function updateANPRImage(media_id) {
        if (media_id === 'None') {
            setANPRImage(null);
            setANPRImageLoading(false);
            return;
        }
        setANPRImageLoading(true);
        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                try {
                    setANPRImage(response.data.media[0].image);
                } catch (error) {
                    console.error('Error getting ANPR image:', error);
                    setANPRImage(null);
                }
            }
        }
        setANPRImageLoading(false);
    }

    async function updateOverviewImage(media_id) {
        if (media_id === 'None') {
            setOverviewImage(null);
            setOverviewImageLoading(false);
            return;
        }
        setOverviewImageLoading(true);
        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                try {
                    setOverviewImage(response.data.media[0].image);
                } catch (error) {
                    console.error('Error getting overview image:', error);
                    setOverviewImage(null);
                }
            }
        }
        setOverviewImageLoading(false);
    }

    async function updateMatchANPRImage(matchId, media_id) {
        if (media_id === 'None') return;

        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                setMatchImages((prev) => ({
                    ...prev,
                    [matchId]: {
                        ...prev[matchId],
                        anpr_image: response.data.media[0].image,
                    },
                }));
            }
        }
    }

    async function updateMatchOverviewImage(matchId, media_id) {
        if (media_id === 'None') return;

        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                setMatchImages((prev) => ({
                    ...prev,
                    [matchId]: {
                        ...prev[matchId],
                        overview_image: response.data.media[0].image,
                    },
                }));
            }
        }
    }

    async function openModal() {
        setShowModal(true);
        if (transit) {
            if (transit.entry_device_event) {
                updateANPRImage(transit.entry_device_event.anpr_image_media_id);
                updateOverviewImage(transit.entry_device_event.overview_image_media_id);
            }
        }
        getPossibleMatches();
    }

    async function getPossibleMatches() {
        setLoadingPossibleMatches(true);
        if (transit && transit.entry_device_event) {
            const response = await backendAPI.getPossibleTransitMatches(transit.id);
            if (response.data) {
                setPossibleMatches(response.data);
                response.data.forEach((match) => {
                    updateMatchANPRImage(match.id, match.exit_device_event.anpr_image_media_id);
                    updateMatchOverviewImage(match.id, match.exit_device_event.overview_image_media_id);
                });
            }
        }
        setLoadingPossibleMatches(false);
    }

    async function matchTransit(exit_transit) {
        const response = await backendAPI.matchTransits(transit.id, exit_transit.id);
        if (response.status === 200) {
            refetch_report_callback();
            setShowModal(false);
        } else {
            alert('Error matching transits');
        }
    }

    function closeModal() {
        setShowModal(false);
    }

    function getBestClass(entry, exit) {
        if (entry && exit) {
            return entry.vehicle_class_confidence > exit.vehicle_class_confidence ? entry.vehicle_class : exit.vehicle_class;
        }
        return entry ? entry.vehicle_class : exit ? exit.vehicle_class : '---';
    }

    return (
        <div style={{ float: "left", ...(showModal ? overlayStyles : {}) }}>
            <FontAwesomeIcon icon={faArrowsH} style={{ cursor: "pointer", marginRight: '20px' }} onClick={() => openModal()} title="Manual match to exit event" />
            <Modal show={showModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Transit Matching</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {transit != null ? (
                        <Row>
                            {/* Entry Event Details */}
                            <Row>
                                <center><strong>ENTRY EVENT</strong></center>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={6} lg={6}><strong>VRN: </strong>{transit.entry_device_event ? transit.entry_device_event.vrn : "---"}</Col>
                                <Col className="mt-1 mb-1" xs={6} lg={6}><strong>Entry: </strong>{transit.entry_device_event ? transit.entry_device_event.event_time : '---'}</Col>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={6} lg={6}><strong>Class: </strong>{getBestClass(transit.entry_device_event, transit.exit_device_event)}</Col>
                            </Row>

                            {/* Images */}
                            <Row>
                                <Col className="mt-1 mb-1" xs={6} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {overview_image_loading ? (
                                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : overview_image ? (
                                        <ImageModal imageSrc={`data:image/jpeg;base64,${overview_image}`} altText="Entry Overview">
                                            <img src={`data:image/jpeg;base64,${overview_image}`} style={{ width: '100%' }} alt="Not available" />
                                        </ImageModal>
                                    ) : (
                                        <p>Overview image not available</p>
                                    )}
                                </Col>
                                <Col className="mt-1 mb-1" xs={6} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {anpr_image_loading ? (
                                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : anpr_image ? (
                                        <ImageModal imageSrc={`data:image/jpeg;base64,${anpr_image}`} altText="Entry ANPR">
                                            <img src={`data:image/jpeg;base64,${anpr_image}`} style={{ width: '100%' }} alt="Not available" />
                                        </ImageModal>
                                    ) : (
                                        <p>ANPR image not available</p>
                                    )}
                                </Col>
                            </Row>

                            <hr className="my-3" />
                            <Row>
                                <center><strong>POSSIBLE EXIT EVENT MATCHES</strong></center>
                                {loading_possible_matches ? (
                                    <Spinner className="spinner-border text-primary" animation="border" role="status" style={{ margin: 'auto' }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : possible_matches.length > 0 ? possible_matches.map((possible_match, idx) => (
                                    <Row key={idx}>
                                        <Row>
                                            <Col className="mt-1 mb-1" xs={6} lg={5}>
                                                <strong>VRN: </strong>{possible_match.exit_device_event.vrn}
                                            </Col>
                                            <Col className="mt-1 mb-1" xs={6} lg={6}>
                                                <strong>Exit: </strong>{possible_match.exit_device_event.event_time}
                                            </Col>
                                            <Col className="mt-1 mb-1" xs={1} lg={1}>
                                                <FontAwesomeIcon icon={faArrowsH} style={{ cursor: "pointer" }} onClick={() => {
                                                        if (window.confirm('Are you sure you want to match this transit?')) {
                                                            matchTransit(possible_match);
                                                        }
                                                    }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-1 mb-1" xs={6} lg={5}>
                                                <strong>Class: </strong>{possible_match.exit_device_event.vehicle_class ? possible_match.exit_device_event.vehicle_class : '---'}
                                            </Col>
                                            <Col className="mt-1 mb-1" xs={3} lg={6}>
                                                <strong>Score: </strong>{possible_match.match_score}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-1 mb-1" xs={6} lg={6}>
                                                {/* Display ImageModals for each possible match */}
                                                {matchImages[possible_match.id] && matchImages[possible_match.id].overview_image && (
                                                    <ImageModal imageSrc={`data:image/jpeg;base64,${matchImages[possible_match.id].overview_image}`} altText="Exit Overview">
                                                        <img src={`data:image/jpeg;base64,${matchImages[possible_match.id].overview_image}`} style={{ width: '100%'}} alt="Not available" />
                                                    </ImageModal>
                                                )}
                                            </Col>
                                            <Col className="mt-1 mb-1" xs={6} lg={6}>
                                                {matchImages[possible_match.id] && matchImages[possible_match.id].anpr_image && (
                                                    <ImageModal imageSrc={`data:image/jpeg;base64,${matchImages[possible_match.id].anpr_image}`} altText="Exit ANPR">
                                                        <img src={`data:image/jpeg;base64,${matchImages[possible_match.id].anpr_image}`} style={{ width: '100%'}} alt="Not available" />
                                                    </ImageModal>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr className="my-3" />
                                    </Row>
                                )) : <center><strong>No possible matches</strong></center>}
                            </Row>
                        </Row>
                    ) : (
                        <p>No live transits available for this zone.</p>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default TransitMatchModal;
