import React, { useState, useEffect } from "react";
import { Table, Button, Form, Spinner, Modal, Pagination, Alert } from "react-bootstrap";
import { DeviceEventModal } from './DeviceEventModal.js';

export function TraceComponent({ backendAPI, show, onHide }) {
  const [searchText, setSearchText] = useState("");
  const [VRNHistory, setVRNHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // API uses this as limit
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (!show) {
      setSearchText("");
      setVRNHistory([]);
      setTotalRecords(0);
      setError(null);
      setCurrentPage(1); // Reset pagination when closing
      setOffset(0);
    }
  }, [show]);

  const fetchData = async (query, newOffset = 0) => {
    setLoading(true);
    setError(null);

    try {
      const response = await backendAPI.trace(query, newOffset, pageSize);
      setVRNHistory(response.data.device_events);
      setTotalRecords(parseInt(response.data.total, 10));
      setOffset(newOffset); // Update the offset
      if (response.data.device_events.length === 0) {
        setError("No results found.");
      }
    } catch (err) {
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCurrentPage(1);
    fetchData(searchText, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * pageSize;
    setCurrentPage(page);
    fetchData(searchText, newOffset); // Fetch correct data for page
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Body>
        <h4 className="text-center mb-4">Search Vehicle</h4>

        {/* Show the search input if there are no results yet */}
        {VRNHistory.length === 0 && !error && (
          <Form onSubmit={handleSubmit} className="mb-3">
            <Form.Control
              type="text"
              placeholder="Enter vehicle number..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              required
              autoFocus
            />
            <Button type="submit" variant="primary" disabled={loading} className="mt-2 w-100">
              {loading ? <Spinner as="span" animation="border" size="sm" /> : "Search"}
            </Button>
          </Form>
        )}

        {/* Show error if API call fails or no results are found */}
        {error && <Alert variant="danger" className="text-center">{error}</Alert>}

        {/* Show results table if data is available */}
        {VRNHistory.length > 0 && !error && (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>VRN</th>
                  <th>Event Time</th>
                  <th>Location</th>
                  <th>VRN Score</th>
                  <th>Vehicle Class</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {VRNHistory.map((item, index) => (
                  <tr key={index}>
                    <td>{item.vrn || "N/A"}</td>
                    <td>{item.event_time || "N/A"}</td>
                    <td>{item.location || "N/A"}</td>
                    <td>{item.vrn_score || "N/A"}</td>
                    <td>{item.vehicle_class || "N/A"}</td>
                    <td>
                      <DeviceEventModal device={null} backendAPI={backendAPI} websocket={null} liveDeviceEventUpdate={false} device_event={item} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            
            {/* Pagination - Only show if there are more records than page size */}
            {totalRecords > pageSize && (
              <Pagination className="justify-content-center">
                {[...Array(Math.ceil(totalRecords / pageSize)).keys()].map((page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currentPage}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            )}
          </>
        )}
      </Modal.Body>
      
      <Modal.Footer className="justify-content-center">
        {/* <Button variant="secondary" onClick={onHide} className="w-100">
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};
